<template>
  <VContainer>
    <TTExample
      file="components/TTNotify/examples/TTNotify.success"
    />

    <TTExample
      file="components/TTNotify/examples/TTNotify.info"
    />

    <TTExample
      file="components/TTNotify/examples/TTNotify.warning"
    />

    <TTExample
      file="components/TTNotify/examples/TTNotify.error"
    />
  </VContainer>
</template>

<script>

export default {
  name: 'Notify',
  data() {
    return {};
  },
};
</script>
